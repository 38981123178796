@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --root-background-color: #FFFEF1;
}

:root.dark {
  --root-background-color: #182748;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-10px) translateX(10px);
  }
  50% {
    transform: translateY(0) translateX(20px);
  }
  75% {
    transform: translateY(10px) translateX(10px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.animate-pulse {
  animation: pulse 3s infinite;
}

html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: visible; /* Cho phép scroll */
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--root-background-color);

}
/* #root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
} */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--root-background-color);
}
.main-container {
  flex: 1;
  min-height: 100vh; /* Thay vì dùng calc(var(--vh, 1vh) * 100) */
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  background-color: inherit;
}

 /* Thêm font chữ */
.font-philosopher {
  font-family: 'Philosopher', sans-serif;
}

.font-quicksand {
  font-family: 'Quicksand', sans-serif;
}

.font-cinzel {
  font-family: 'Cinzel', serif;
}

.font-playfair {
  font-family: 'Playfair Display', serif;
}

.font-cormorant {
  font-family: 'Cormorant', serif;
}
body {
  transition: background-color 0.3s ease;
}

html {
  min-height: 100vh;
  background-color: #FFFEF1; /* custom-cream color */
}

html.dark {
  background-color: #2E5090; /* dark mode color */
}

/* Đảm bảo scroll mượt */
* {
  -webkit-overflow-scrolling: touch;
}

/* Transition cho background */
.fixed {
  transition: background-color 0.3s ease-out;
}